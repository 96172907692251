import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './css/main.css'

const restApiHost = 'https://online-casino-auth.com'
export default restApiHost;

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


