import React from 'react'

import {useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import restApiHost from '..'
import 'react-loading-skeleton/dist/skeleton.css';
import '../css/game.css'
import { useGlobalState, setGlobalState  } from '../state';
import Skeleton from 'react-loading-skeleton';
import leftVenokImage from '../img/venok_left.png'
import rightVenokImage from '../img/right_venok.png'
import { useRef } from 'react';
import Break from './Break';
import { Link } from 'react-router-dom';
import goBackImage from '../img/go_back.png'


const Sub = ({ subcategory }) => {
    if (subcategory === 'Новое') {
      return <div className='game-subtitle'>Новое</div>;
    }
    
    if (subcategory === 'Популярное') {
        return <div className='game-popular'>Популярное</div>
      }    


    if (subcategory === 'Выбор редакции') {
        return <div className='game-choose'>
        <div className='venok-container'>
            <img src={leftVenokImage} className='venok' alt='error'/>
        </div>
        <div className='title-sub'>Выбор редакции</div>
        <div className='venok-container'>
            <img src={rightVenokImage} className='venok' alt='error'/>
        </div>
      </div>
    }
  
    
    return null;
  };
  

function setGlobalGames (games) {
    setGlobalState('games', games )
  }


function GamePage () {

    const navigate = useNavigate();
    const ip = useGlobalState('ip')[0];
    const windowRef = useRef(window);

    const [gameId, setGameId] = useState(null)
    const [gameInfo, setGameInfo] = useState(null);

    const [recomendedGames, setRecomendedGames] = useState(null) 
    const games = useGlobalState('games')[0].data
    
    const location = useLocation();
    
    
    
    useEffect(() => {
        setGameInfo(null);
        windowRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        const generateRandomArray = () => {
            // Создаем копию оригинального массива
            const tempArray = [...games];
        
            // Выбираем 4 случайных элемента из копии
            const newRandomArray = [];
            for (let i = 0; i < 4; i++) {
              const randomIndex = Math.floor(Math.random() * tempArray.length);
              newRandomArray.push(tempArray[randomIndex]);
              tempArray.splice(randomIndex, 1); // Удаляем элемент из копии, чтобы не повторялись
            }
        
            setRecomendedGames(newRandomArray);
          };
        
        const getGameId = () => {
            const searchParams = new URLSearchParams(location.search);
            const gameIdParam = searchParams.get('game_id');
            const gameIds = parseInt(gameIdParam, 10);
            setGameId(gameIds);
            
          };

        const getGames = async () => {
            const searchParams = new URLSearchParams(location.search);
            const countryName = searchParams.get('country');
            try {
                    const response = await fetch(restApiHost + `/api/get_games/` + countryName);
                    const data = await response.json();
                    setGlobalGames(data);
                }
            catch {
                console.log('Error getGames')
            }
        }

        const fetchGameInfo = async () => {
          try {
            if (gameId !== null){ 
            const response = await axios.get(restApiHost + `/api/get_game_info/${gameId}/`);
            setGameInfo(response.data);
        }
          } catch (error) {
            console.error('Error fetching game info:', error);
          }
        };
        
        getGameId();
        fetchGameInfo();
        console.log(games)
        if (games.length === 0) {
            getGames();
            if (games.length !== 0) {
                generateRandomArray();
            }
        }
        else {
            if (games.length !== 0) {
                generateRandomArray();
            }
        }
        
      }, [gameId, location, games]);

    async function goToFrame (event, game_id, link) {
        
        event.stopPropagation();
        await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);
        
        
        window.open(link, '_self')
           
        };
    
    async function goToPage(game_id) {
        await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);

        navigate(`/game?game_id=${game_id}`);
    }

    return (
        <div >
            {gameInfo !== null && recomendedGames !== null ? (
            <div className='gamepage'>
                <div className='iframe_header'>
                    <Link className='iframe-go_back_container' to='/'>
                            <div className='iframe-image-container'>
                                <img src={goBackImage} alt='error' className='iframe-image'/>
                            </div>
                            <div className='iframe-header__text'>Вернуться в лобби</div>
                    </Link>
                </div>
                <div className='game_upper_section'>
                    <div className='gamepage-image-container'>
                        <img className='gamepage-image' src = {gameInfo.main_image} alt='error'/>
                    </div>
                    <div className='upper-section-block'>
                        <div className='upper-upper-section'>
                            <div className='game-title'>
                                {gameInfo.title}
                            </div>
                            <div className='game-short-desc'>
                                {gameInfo.short_description}  
                            </div>
                        </div>
                        <div className='go-to-game-button-section'>
                            
                            <button className='go-to-game-button' onClick={(event) => {goToFrame(event, gameInfo.id, gameInfo.link)} }>Открыть</button>
                            
                        </div>
                        
                    </div>
                    
                </div>
                <div className='game-page-scrolable-container'>
                    {gameInfo.images.map((image) => (
                        <div className='scrolable-container-game-page-section' key={image.id}>
                            <img src={image.url} alt='error' className='game-page-scrolable-image'/>

                        </div>
                    ))}   
                    
                </div>
                <div className='game-page-description-section'>
                    <div className='description_heading'>Описание</div>
                    <div className='game-page-description'>
                        {gameInfo.description}
                    </div>
                </div>
                <hr className='breakable-gamepage'/>

                <div className='section__section'>
                    <div className='section__section-title'>Вам также могут понравиться</div>
                    <div className='section__section-container'>
                        {recomendedGames.map((item) => (
                        <div className='section__section-game' key={item.id} onClick={() => goToPage(item.id)}>
                            <div className='section__section-game-image-container'>
                                <img src={item.main_image} alt='error' className='section__section-game-image'></img>
                            </div>
                            <div className='section__section_game-middle-container'>
                                <div className='game-title'>{item.title}</div>
                                <Sub subcategory={item.subcategory}/>
                                <div className='game-short-desc'>{item.short_description}</div>
                            </div>
                            <div className='game-button-container'>
                                
                                <button className='section__section_buton' onClick={(event) => {goToFrame(event, item.id, item.link)}}>Открыть</button>
                            </div>
                        </div>
                        ))}
                        
                    </div>
                </div>
                <Break/>
            </div>) : (
                <div className='gamepage'>
                
                <div className='game_upper_section'>
                    <div className='gamepage-image-container'>
                        <Skeleton style={{height: '100%', width: '100%'}}/>
                    </div>
                    <div className='upper-section-block'>
                        <div className='upper-upper-section'>
                            <div className='game-title'>
                                <Skeleton slyle={{height: '28px', width:'20px'}}/>
                            </div>
                            <div className='game-short-desc'>
                                <Skeleton style={{height:'18px',width:'258px'}}/>
                            </div>
                        </div>
                        <div className='go-to-game-button-section'>
                            <Skeleton style={{height:'30px', width:'84.45px'}}/>
                        </div>
                        
                    </div>
                    
                </div>
                <div className='game-page-scrolable-container'>
                    
                    <div className='scrolable-container-game-page-section'>
                        <Skeleton style={{width: '229px', height: '407.1px'}}/>
                    </div>
                    <div className='scrolable-container-game-page-section'>
                        <Skeleton style={{width: '229px', height: '407.1px'}}/>
                    </div>
                    <div className='scrolable-container-game-page-section'>
                        <Skeleton style={{width: '229px', height: '407.1px'}}/>
                    </div>
                    <div className='scrolable-container-game-page-section'>
                        <Skeleton style={{width: '229px', height: '407.1px'}}/>
                    </div> 
                    
                </div>
                <div className='game-page-description-section'>
                    <Skeleton style={{height: '266px', width:'92vw' }}/>
                    
                </div>
                <hr className='breakable-gamepage'/>

                <div className='section__section'>
                    <div className='section__section-title'>Вам также могут понравиться</div>
                    <div className='section__section-container'>
                        
                        <div className='section__section-game'>
                            <div className='section__section-game-image-container'>
                                <Skeleton style={{width: '100%', height: '100%'}}/>
                            </div>
                            <div className='section__section_game-middle-container'>
                                <div className='game-title'><Skeleton/></div>
                                <div className='game-subtitle'><Skeleton/></div>
                                <div className='game-short-desc'><Skeleton/></div>
                            </div>
                            <div className='game-button-container'>
                                <Skeleton/>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                
            </div>
            )}
    </div>
    )
}

export default GamePage;