import React from "react"
import '../css/search.css'
import '../css/main.css'
import Casino from "../components/Casino"
import Bets from "../components/Bets"
import Break from "../components/Break"
import {useEffect } from "react"
import { useGlobalState, setGlobalState } from "../state"
import restApiHost from ".."
import { useState } from "react"
import clearInputImage from '../img/clear_input.png'
import searchIcon from '../img/search.png'
import duckGif from '../img/utya.gif'
import leftVenokImage from '../img/venok_left.png'
import rightVenokImage from '../img/right_venok.png'
import RecentlyOpened from "../components/RecentlyOpened"
import { useNavigate } from "react-router-dom"
import vpnGif from '../img/vpn_square.gif'
import MainPageOffline from "../components/MainPageOffline"

const Sub = ({ subcategory }) => {
    if (subcategory === 'Новое') {
      return <div className='game-subtitle'>Новое</div>;
    }
  
    if (subcategory === 'Выбор редакции') {
        return <div className='game-choose'>
        <div className='venok-container'>
            <img src={leftVenokImage} className='venok' alt='error'/>
        </div>
        <div className='title-sub'>Выбор редакции</div>
        <div className='venok-container'>
            <img src={rightVenokImage} className='venok' alt='error'/>
        </div>
      </div>
    }
  
    
    return null;
  };




function setGlobalGames (games) {
    setGlobalState('games', games )
  }

function setGlobalIp (ip) {
    setGlobalState ('ip', ip)
}


function MainPage () {
    const navigate = useNavigate();
    const games = useGlobalState('games')[0];
    const ip = useGlobalState('ip')[0];
    const [isRequestSended, setRequestSended] = useState(false);
    const [recentlyGames, setRecenltyGames] = useState([]);
    const [countryName, setCountryName] = useState(null);
    const [isLoadedRecently, setLoadedRecently] = useState(false);

   
    


    useEffect(() => {

        console.log(countryName)        

        async function checkIfUsIp () {
        try {
            const resp = await fetch('https://zaymi-online24.store/geo/get_ip/');
            const data = await resp.json();
            const ip = data.ip; 
            setGlobalIp(ip);
            
        } catch (error) {
            console.error('Ошибка при проверке IP-адреса:', error);
            return false;
        }
        };

        async function getCountryName () {
            try {
                const resp = await fetch('https://zaymi-online24.store/geo/get_country_name/');
                const data = await resp.json();
                const country = data.country_name.toLowerCase();
                setCountryName(country);
            }
            catch {
                console.error('Ошибка получения страны по ip')
            }
        }


        
        async function fetchData() {
            try {
                
                const response = await fetch(restApiHost + `/api/get_games/` + countryName);
                const data = await response.json();
                setGlobalGames(data);
                setRequestSended(true);
                
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        }
       
        if (countryName === null) {
            getCountryName();
        }

        if (games.data.length === 0 && !isRequestSended && countryName !== null) {
            fetchData();
        }
        if (ip === ''){
            checkIfUsIp();
        }
        
    

        
    }, [games, countryName, ip, isRequestSended]);

    const [inputValue, setInputValue] = useState('');
    const [inputClick, setInputClick] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        
        if (event.target.value.length >= 1) {
            setInputClick(true)
        }else{
            setInputClick(false)
        }
      };

    const ClearInput = () => {
        setInputValue('')
        setInputClick(false)
    }

    
    const filteredOfers = games.data.filter(offer => {
        return [offer.title, ...offer.title_for_look].some(item =>
            item.toLowerCase().includes(inputValue.toLowerCase())
        );})
    
    
    
    async function getRecentlyOpened () {
            
            if (ip !== '' && !isLoadedRecently){
                const response = await fetch(restApiHost + `/api/get_recently_games/` + ip + `/`);
                const data = await response.json();
                if (data.data.length > 0){
                    setRecenltyGames(data.data)
                }
                setLoadedRecently(true);
            }
        };
    
    async function goToFrame (event, game_id, link) {
        
        event.stopPropagation();
        await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);
        
        console.log(1);
        window.open(link, '_self')
         
        };
    
    async function goToPage(game_id) {
        await fetch(restApiHost + `/api/add_recently_game/` + game_id + '/' + ip + `/`);
        

            navigate(`/game?game_id=${game_id}&country=${countryName}`);
        
    }

    
    
        return (<div className="main" onLoad={getRecentlyOpened}>
                {countryName === 'russia' || countryName === null ?(
                    <div>
                    <div className='search'>
                        <div className='image-search-container'>
                            <img src={searchIcon} className='search-image' alt='error'/>
                        </div>
                        <input value={inputValue} placeholder = 'Поиск' className='search-input'  onChange={handleInputChange}></input>
                        {inputClick && <div className="clear_input-image-container">
                            <img className='clear-input-image' src={clearInputImage} alt='error' onClick={ClearInput}/>
                            </div>}
                    </div>
                    
                    {inputClick ? (
                        
                        <div className='section__section'>
                        {filteredOfers.length >= 1 ? (
                            <div className='section__section-container'>
                                
                                    {filteredOfers.map((item) => (
                                    <div className='section__section-game' key={item.id} onClick={() => {goToPage(item.id)}}>
                                        <div className='section__section-game-image-container'>
                                            <img src={item.main_image} alt='error' className='section__section-game-image'></img>
                                        </div>
                                        <div className='section__section_game-middle-container'>
                                            <div className='game-title'>{item.title}</div>
                                            <Sub subcategory={item.subcategory}/>
                                            <div className='game-short-desc'>{item.short_description}</div>
                                        </div>
                                        <div className='game-button-container'>
                                            
                                            <button className='section__section_buton' onClick={(event) => goToFrame(event,item.id, item.link)}>Открыть</button>
                                            
                                        </div>
                                    </div>
                                    ))}
                                    <div className='breakable_line'></div>
                            </div>) : (
                                <div className="zero_results">
                                    <div className="zero_results_image_container">
                                        <img src={duckGif} alt="error" className="utya_image"/>
                                    </div>
                                    <div className="text-container">
                                        <div className="main_bi">Нет результатов</div>
                                        <div className="no_found">По запросу "{inputValue}" ничего не найдено.</div>
                                        <div className="try_again">Попробуйте новый поиск</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>)
                : (
                    <div>
                        <RecentlyOpened data={recentlyGames} isLoaded={isLoadedRecently} />
                        <Casino country={countryName}/>
                        <Bets country={countryName}/>
                        <Break/>
                        
                    </div>)}
                </div>) : (
                    <div>
                    <MainPageOffline/>
                    <div className="vpn-image-container"> 
                    <img src={vpnGif} alt='error' className="vpn-background"/>
                    </div>
                </div>)}
                
                </div>
                
        )   
    

    

}


export default MainPage